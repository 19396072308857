<template>
  <div class="container">
    <BasicTitle title="Nueva Votación" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Título *" />
      <BorderInput
        v-model="voting.data.title"
        label="Título *"
        @keyup.enter="onSave"
      />
      <FormError :show="voting.rules.title" message="Ingrese un título" />
      <BasicLabel label="Descripción *" />
      <OutlinedTextArea v-model="voting.data.description" />
      <FormError
        :show="voting.rules.description"
        message="Ingrese una descripción"
      />
      <BasicLabel label="Fecha de inicio *" />
      <BorderDate v-model="voting.data.fromDate" label="Fecha de inicio *" />
      <FormError :show="voting.rules.fromDate" message="Seleccione una fecha" />
      <BasicLabel label="Fecha de fin *" />
      <BorderDate v-model="voting.data.toDate" label="Fecha de fin *" />
      <FormError :show="voting.rules.toDate" message="Seleccione una fecha" />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import BorderDate from "../../widgets/calendar/BorderDate.vue";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode } from "../../constants";
import { validation, actions, aws } from "../../constants";
import BorderFile from "../../widgets/file/BorderFile.vue";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    BorderDate,
    FormError,
    PrimaryButton,
    BorderFile,
    OutlinedTextArea,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const voting = reactive({
      data: {
        id: -1,
        title: "",
        description: "",
        fromDate: "",
        toDate: "",
      },
      rules: {
        title: false,
        description: false,
        fromDate: false,
        toDate: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!voting.data.title) {
        voting.rules.title = true;
        valid = false;
      }
      if (!voting.data.description) {
        voting.rules.description = true;
        valid = false;
      }
      if (!voting.data.fromDate) {
        voting.rules.fromDate = true;
        valid = false;
      }
      if (!voting.data.toDate) {
        voting.rules.toDate = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: voting.data.id,
        title: voting.data.title,
        description: voting.data.description,
        fromDate: new Date(voting.data.fromDate).getTime(),
        toDate: new Date(voting.data.toDate).getTime(),
        buildingId: store.state.general.selectedBuilding,
      };

      /* let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(
          actions.votingActions.update,
          request
        );
      } else {

      } */
      const response = await store.dispatch(
        actions.votingActions.create,
        request
      );
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedVotings", [response.voting]);
        store.commit("setOpenMode", openMode.CREATE);
        router.push("/votaciones/detalle/asociar");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(() => {
      if (!store.state.openMode) router.push("/votaciones/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      /* if (store.state.openMode === openMode.DETAIL) {
        const selected = store.state.voting.selectedVotings[0];
        voting.data.id = selected.id;
        voting.data.title = selected.title;
        voting.data.text = selected.text;
        voting.data.appointmentDate = new Date(selected.appointmentDate);
        voting.data.indLink = selected.link ? "true" : "";
        voting.data.link = selected.link;
        voting.disabled = true;
      } */
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (voting.data.title) voting.rules.title = false;
      if (voting.data.description) voting.rules.description = false;
      if (voting.data.fromDate) voting.rules.fromDate = false;
      if (voting.data.toDate) voting.rules.toDate = false;
    });

    return { store, voting, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.link-label {
  margin-right: 1rem;
  font-family: "klavika";
  color: var(--primary);
}
</style>
